
// Frontend
import htmlIcon from '../assets/images/skills/frontend/html.png';
import cssIcon from '../assets/images/skills/frontend/css.png';
import boostrapIcon from '../assets/images/skills/frontend/boostrap.png';
import javascriptIcon from '../assets/images/skills/frontend/javascipt.png';
import reactIcon from '../assets/images/skills/frontend/react.svg';

// Backend
import phpIcon from '../assets/images/skills/backend/PHP-logo.svg.png';
import pythonIcon from '../assets/images/skills/backend/python.svg';
import nodeIcon from '../assets/images/skills/backend/nodejs.svg';
import mysqlIcon from '../assets/images/skills/backend/mysql.svg';
import mongoIcon from '../assets/images/skills/backend/mongo-db.svg';

// Server
import cpanelIcon from '../assets/images/skills/server/cpanel.png';
import hostingerIcon from '../assets/images/skills/server/hostinger.png';
import digitaloceanIcon from '../assets/images/skills/server/digitalocean.png';
import awsIcon from '../assets/images/skills/server/aws.png';

// Others
import wordpressIcon from '../assets/images/skills/others/wordpress.png';
import gitIcon from '../assets/images/skills/others/git.svg';
import githubIcon from '../assets/images/skills/others/GitHub.png';
import vscodeIcon from '../assets/images/skills/others/vscode.png';
import postmanIcon from '../assets/images/skills/others/postman.svg';
import figmaIcon from '../assets/images/skills/others/figma.svg';

// Work Experence
import TechrowthLogo from '../assets/images/job/techrowth.jpg';
import ItdigiLogo from '../assets/images/job/it-digi.jpg';
import TsfLogo from '../assets/images/job/tsf.png';
import TenLogo from '../assets/images/job/ten.jpg';

// Education
import jainLogo from '../assets/images/edu/jain-univercity.png';
import raisoniLogo from '../assets/images/edu/raisoni.png';

// Projects

import todoProject from '../assets/images/projects/todo-app.png';
import ecommfrontProject from '../assets/images/projects/e-commorce-frontend.png';
import allriseProject from '../assets/images/projects/allrise-tech.png';
import ecommdashboardProject from '../assets/images/projects/e-commorce.png';
import mayurProject from '../assets/images/projects/mayur-ui.png';
import messProject from '../assets/images/projects/mess-magement.jpeg';
import qrcodeProject from '../assets/images/projects/qr-code-gen.png';
import solocodeProject from '../assets/images/projects/solocode.jpeg';
import tempconProject from '../assets/images/projects/temp-conversion.png';
import vexaProject from '../assets/images/projects/vexa.png';
import validusProject from '../assets/images/projects/website-ui.png';
import wetherProject from '../assets/images/projects/wether.png';



export const Bio = {
  name: "Mayur Patil",
  roles: [
    "Full Stack Developer",
    "Web Application Dev",
    "SEO specialist",
    "Tech Enthusiast",
  ],
  description:
    "As a full stack developer, I have expertise in both front-end and back-end development, making me capable of handling a variety of tasks related to web development.",
  github: "https://github.com/Mark-218",
  resume:
    "https://drive.google.com/file/d/1OvRxUK0aFZQj6q9LRoq0YQb44YVVYeOo/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/mayurpatil218/",
  twitter: "https://twitter.com/Mayurpatil218",
  insta: "https://www.instagram.com/mayurpatil_10_/",
  facebook: "https://www.facebook.com/patil.mayur.501598",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "HTML",
        image: htmlIcon,
      },
      {
        name: "CSS",
        image: cssIcon,
      },
      {
        name: "Bootstrap",
        image: boostrapIcon,
      },
      {
        name: "JavaScript",
        image: javascriptIcon,
      },
      {
        name: "React Js",
        image: reactIcon,
      },


    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "PHP",
        image: phpIcon,
      },
      {
        name: "Django",
        image: pythonIcon ,
      },
      {
        name: "Node Js",
        image: nodeIcon,
      },

      {
        name: "MySQL",
        image: mysqlIcon,
      },

      {
        name: "MongoDB",
        image: mongoIcon,
      },

    ],
  },
  {
    title: "Deployment",
    skills: [
      {
        name: "cPanel Hosting",
        image: cpanelIcon,
      },
      {
        name: "Hostinger",
        image: hostingerIcon,
      },
      {
        name: "Digitalocian",
        image: digitaloceanIcon,
      },
      {
        name: "Amazon Web Services",
        image: awsIcon,
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Wordpress",
        image: wordpressIcon,
      },
      {
        name: "Git",
        image: gitIcon,
      },
      {
        name: "GitHub",
        image: githubIcon,
      },
    
      {
        name: "VS Code",
        image: vscodeIcon,
      },
      {
        name: "Postman",
        image: postmanIcon,
      },
      {
        name: "Figma",
        image: figmaIcon,
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: TechrowthLogo ,
    role: "Web Developer",
    company: "Techrowth Pvt. Ltd.",
    date: "May 2022 - Present",
    desc: "Web Developer at Techrowth Pvt. Ltd., specializing in front-end and back-end development. Proficient in Figma to HTML conversion, WordPress, MySQL, and PHP. Skilled in deploying websites, integrating payment gateways, and using React.js to create dynamic web experiences. Experienced in WooCommerce for e-commerce solutions and Bootstrap 5 for responsive design.",
    skills: [
      "Figma to HTML",
      "WordPress",
      "MySQL",
      "Web Application Development",
      "Website Deployment",
      "Bootstrap5",
      "PhpMyAdmin",
      "WooCommerce",
      "Front-End Development",
      "PHP",
      "React.js",
      "Payment Gateway",
    ],
    // doc: "doc url",
  },
  {
    id: 1,
    img: ItdigiLogo,
    role: "Web Application Developer",
    company: "IT Digi Solutions",
    date: "Sep 2021 - May 2022",
    desc: "Web Application Developer at IT Digi Solutions, specializing in creating robust web applications. Proficient in HTML5, CSS3, Bootstrap 5 for responsive design, and Python for backend development. Skilled in Django and Django REST Framework for building scalable web applications, and experienced in data analysis using PyTorch, NumPy, Pandas, and Celery for background task processing.",
    skills: [
      "Web Application Development",
      "HTML5",
      "CSS3",
      "Boostrap5",
      "Python",
      "Django",
      "Django REST Framework",
      "PyTorch",
      "NumPy",
      "Pandas",
      "Celery",
    ],
  },
  {
    id: 2,
    img: TsfLogo,
    role: "Web Development & Designing",
    company: "The Sparks Foundation",
    date: "Aug 2021 - Oct 2021",
    desc: "Intern in Web Development & Design at The Sparks Foundation, focusing on HTML5, CSS3, and website development. Utilizing Bootstrap for responsive design. Gaining hands-on experience in crafting web solutions.",
    skills: [
      "HTML5",
      "CSS3",
      "Website Development",
      "Boostrap",
    ],
  },
  
 
  {
    id: 3,
    img: TenLogo,
    role: "Wordpress Development Associate",
    company: "The Entrepreneurship Network",
    date: "April 2021 - July 2021",
    desc:"Intern as a WordPress Development Associate at The Entrepreneurship Network, gaining valuable skills in WordPress development, website deployment, and web development.",
    skills: [
      "Wordpress",
      "Development",
      "Deployment",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: jainLogo,
    school: "Jain (Deemed-to-be University)",
    date: "Jul 2022 - Present ",
    grade: "No Data",
    desc: "Currently pursuing a Master of Computer Applications (MCA) at Jain (Deemed-to-be-University). Seeking to enhance my skills and knowledge in the field of computer science to contribute effectively to the tech industry.",
    degree: "Master of Computer Applications - MCA",
  },
  {
    id: 1,
    img: raisoniLogo,
    school: " G. H. Raisoni Institute of Business and Management, Jalgaon",
    date: "Jul 2019 - Jul 2022",
    grade: "10 CGPA",
    desc: "Completed Bachelor of Computer Applications (BCA) at G. H. Raisoni Institute of Business and Management, Jalgaon, to build a strong foundation in computer science and technology.",
    degree: "Bachelor of Computer Applications - BCA",
  },
];

export const projects = [
  {
    id: 9,
    title: "Todo App",
    date: "Jun 2023 - Jul 2023",
    description:
      "The Todo project is a valuable tool designed to help individuals efficiently manage their daily tasks. In an increasingly fast-paced world, staying organized is crucial, and this project offers a simple yet effective solution. Users can easily input their daily tasks, set priorities, deadlines, and even categorize them for better organization. With its user-friendly interface and intuitive features, the Todo project ensures that no task is forgotten or left undone. Whether you're a student juggling assignments, a professional managing work projects, or simply someone looking to enhance daily productivity, this project is your go-to companion. Stay on top of your responsibilities, reduce stress, and achieve your goals with the Todo project's seamless task management capabilities.",
    image: todoProject,
    tags: [
      "HTML5",
      "CSS3",
      "React Js",
      "Netlify",
    ],
    category: "Tools",
    github: "https://github.com/Mark-218/todo-app",
    webapp: "https://mayur-todo.netlify.app/",
  },
  {
    id: 0,
    title: "E-commerce Admin Panel",
    date: "Apr 2023 - Jun 2023",
    description:
      "The E-commerce Admin Panel is an indispensable tool for businesses operating in the digital marketplace. With the ever-expanding world of online commerce, businesses need a robust platform to manage their operations efficiently. This admin panel offers a comprehensive suite of features, enabling businesses to oversee inventory, track sales, and monitor customer interactions seamlessly. It provides real-time insights into website traffic, sales trends, and customer behavior, empowering businesses to make data-driven decisions. Moreover, the E-commerce Admin Panel simplifies the process of adding or updating products, managing pricing, and handling customer inquiries, ensuring a smooth and responsive online shopping experience. Whether you're a small startup or a well-established e-commerce giant, this admin panel is your gateway to success in the dynamic world of online retail. Stay in control of your e-commerce operations and elevate your business to new heights with the E-commerce Admin Panel.",
    image: ecommdashboardProject,
    tags: ["HTML5", "CSS3", "Boostrap5", "JavaScript", "PHP", "XAMPP"],
    category: "web app",
    // member: [
    //   {
    //     name: "Mayur Patil",
    //     img: "https://avatars.githubusercontent.com/u/64485885?v=4",
    //     linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
    //     github: "https://github.com/rishavchanda/",
    //   },
    //   {
    //     name: "Upasana Chaudhuri",
    //     img: "https://avatars.githubusercontent.com/u/100614635?v=4",
    //     linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
    //     github: "https://github.com/upasana0710",
    //   },
    // ],
  },

 
  {
    id: 3,
    title: "Solocode",
    date: "Dec 2021 - Apr 2022",
    description:
      "Solocode is a versatile Quiz and Exam Management System that simplifies assessment and certification processes. Ideal for educational institutions and organizations, it streamlines quiz and exam creation, real-time monitoring, and offers professional certification generation, ensuring efficient and effective assessment management.",
    image: solocodeProject,
    tags: ["HTML5", "CSS3", "Boostrap5", "Python", "Django", "Django ORM"],
    category: "web app",
  },
  {
    id: 3,
    title: "Validus Thailand",
    date: "Jun 2022 - Present",
    description:
      "Validus Thailand is an online shopping platform dedicated to education products and resources, serving the Thai market. It offers a wide range of educational materials, from textbooks and study aids to teaching tools and classroom supplies, catering to the needs of students, educators, and institutions in Thailand. With a user-friendly interface and a diverse product selection, Validus Thailand provides a convenient and comprehensive shopping experience for those seeking educational materials and resources in the region.",
    image: validusProject,
    tags: ["HTML5", "CSS3", "Boostrap5", "JavaScript", "Wordpress", "WooCommerce"],
    category: "Websites",
    webapp: "https://validusthailand.com/",
  },

  {
    id: 3,
    title: "QR code Generator",
    date: "May 2023",
    description:
      "A QR code generator is a versatile tool that simplifies the creation of Quick Response (QR) codes. These codes are essential in today's digital world, serving multiple purposes across various industries. With a QR code generator, you can quickly and effortlessly generate QR codes for websites, contact information, product details, event promotions, and more. Simply input the desired data, and the generator will create a scannable QR code that can be printed or shared digitally. It's an invaluable tool for businesses, marketers, and individuals seeking to enhance user engagement and seamlessly share information in a visually appealing and efficient manner.",
    image: qrcodeProject,
    tags: ["HTML5", "CSS3", "JavaScript"],
    category: "Tools",
    github: "https://github.com/Mark-218/qr-code-generator",
    webapp: "https://mark-218.github.io/qr-code-generator/",
  },
  {
    id: 3,
    title: "E-commerce Website",
    date: "Mar 2023 - Jun 2023",
    description:
      "An E-commerce Website is a dynamic online platform that revolutionizes the way businesses sell products or services. It serves as a virtual storefront, enabling customers to browse, select, and purchase items from the comfort of their homes. With user-friendly navigation, secure payment processing, and a vast product catalog, E-commerce Websites offer an unparalleled shopping experience. They empower businesses, from small startups to global enterprises, to expand their reach, increase sales, and build brand loyalty. In today's digital age, an E-commerce Website is not just a convenience but a necessity for any business looking to thrive in the competitive online marketplace.",
    image: ecommfrontProject,
    tags: ["HTML5", "CSS3", "JavaScript", "Boostrap5", "PHP", "XAMPP"],
    category: "Websites",
  },
  {
    id: 3,
    title: "Weather Detection",
    date: "Mar 2023 - Jun 2023",
    description:
      "The Weather Detection Tool is a valuable resource for anyone seeking up-to-date weather information based on specific cities. With a simple and intuitive interface, this tool allows users to quickly search for weather forecasts, current conditions, and climate data for their desired location. Whether you're planning a trip, need to stay informed about the weather in your area, or just have a curiosity about climate patterns around the world, the Weather Detection Tool provides accurate and timely meteorological data. Stay prepared and make informed decisions by accessing real-time weather information with this indispensable tool.",
    image: wetherProject,
    tags: ["HTML5", "CSS3", "JavaScript", "Boostrap5", "React", "Axios", "API"],
    category: "Tools",
  },
  {
    id: 3,
    title: "Allrise Tech",
    date: "Jun 2023 - Aug 2023",
    description:
      "Allrise Tech represents a significant milestone in technological innovation. This endeavor showcases a commitment to pushing the boundaries of what's possible in the ever-evolving tech landscape. With a focus on cutting-edge solutions and forward-thinking approaches, Allrise Tech is poised to make a lasting impact. Whether it's pioneering new software, revolutionizing hardware design, or leading the charge in emerging technologies, Allrise Tech is at the forefront of innovation. Stay tuned for groundbreaking developments and transformative solutions from this dynamic tech entity.",
    image: allriseProject,
    tags: ["HTML5", "CSS3", "JavaScript", "Boostrap5"],
    category: "Websites",
    webapp: "https://allrisetechnologies.com/",
  },
  {
    id: 10,
    title: "Static Portfolio",
    date: "Jun 2021 - Jul 2021",
    description:
      "Static Portfolio - Your Gateway to My World of Design is a showcase of my personal UI design skills, encapsulated within a sleek and captivating home screen. This minimalistic yet visually engaging user interface offers a glimpse into my design sensibilities and creativity. Through thoughtful layout, elegant typography, and carefully chosen visuals, I invite you to explore my unique design perspective. It's a window into my design journey, and I look forward to sharing more of my creative endeavors with you in the future.",
    image: mayurProject,
    tags: ["HTML5", "CSS3"],
    category: "Websites",
    github: "https://github.com/Mark-218/Mayur.github.io",
    webapp: "https://mark-218.github.io/Mayur.github.io/",
  },
  {
    id: 4,
    title: "Temperature Conversion",
    date: "Jul 2021",
    description:
      "Temperature Conversion Tool: Seamlessly Shift Between Celsius and Fahrenheit Effortlessly convert temperatures from degrees Celsius to Fahrenheit and vice versa with precision and ease. Stay in control of your climate data, whether you're planning a trip or just curious about the weather. It's your go-to resource for accurate temperature conversions, ensuring you're always on top of the degrees, no matter the scale.",
    image: tempconProject,
    tags: ["HTML5", "CSS3", "JavaScript", "Boostrap5", "PHP"],
    category: "Tools",
    github: "https://github.com/Mark-218/temperatureconversion.github.io",
  },
  {
    id: 1,
    title: "Vexa",
    date: "Oct 2022 - Present",
    description:
      "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
    image: vexaProject,
    tags: [
      "React Js",
      "MongoDb",
      "Node Js",
      "Express Js",
      "Redux",
      "NodeMailer",
    ],
    category: "web app",
    webapp: "https://vexa-app.netlify.app/",
  },
  {
    id: 2,
    title: "Mess Management System",
    date: "Mar 2022 - May 2022",
    description:
      "The Mess Management System is an indispensable tool for institutions and organizations responsible for catering to the culinary needs of a diverse group of individuals. In an era where dietary preferences and nutritional requirements vary widely, this system streamlines the process of managing meals efficiently. It allows administrators to plan menus, track inventory, and manage budgets effectively. With real-time reporting and analytics, it enables informed decisions regarding meal offerings and resource allocation. The Mess Management System simplifies meal booking for users, ensuring that everyone's dietary needs are met. Whether you're running a school cafeteria, a corporate dining facility, or a healthcare institution, this system is your key to providing nutritious, customized meals while optimizing resources. Enhance meal management, reduce waste, and improve user satisfaction with the Mess Management System.",
    image: messProject,
    tags: ["HTML5", "CSS3", "Boostrap5", "PHP", "XAMPP"],
    category: "web app",
    github: "https://github.com/Mark-218/Mess-Management",
    // member: [
    //   {
    //     name: "Mayur Patil",
    //     img: "https://avatars.githubusercontent.com/u/64485885?v=4",
    //     linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
    //     github: "https://github.com/rishavchanda/",
    //   },
    //   {
    //     name: "Upasana Chaudhuri",
    //     img: "https://avatars.githubusercontent.com/u/100614635?v=4",
    //     linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
    //     github: "https://github.com/upasana0710",
    //   },
    // ],
  },
];

// export const TimeLineData = [
//   { year: 2017, text: "Started my journey" },
//   { year: 2018, text: "Worked as a freelance developer" },
//   { year: 2019, text: "Founded JavaScript Mastery" },
//   { year: 2020, text: "Shared my projects with the world" },
//   { year: 2021, text: "Started my own platform" },
// ];
